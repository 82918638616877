<template>
    <div>
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-sidebar id="sidebar-AddNewsupplier" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header
            right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                        Add New Supplier :
                    </h3>
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>

                <validation-observer ref="addUsersuppliers">
                    <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">
                        <b-form-group id="name" label="Supplier Name:" label-for="name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input id="name" v-model="form.name" placeholder="Enter Supplier Name">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="email" label="Email:" label-for="email">
                            <validation-provider #default="{ errors }" name="email" rules="required">
                                <b-form-input id="email" v-model="form.email" placeholder="example@domain.com">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="phone" label="Phone:" label-for="phone">
                            <validation-provider #default="{ errors }" name="phone" rules="required">
                                <b-form-input id="phone" v-model="form.phone" type="number" placeholder="123-456-1234">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group id="website" label="Website:" label-for="website">
                            <validation-provider #default="{ errors }" name="website" rules="required">
                                <b-form-input id="website" v-model="form.website" placeholder="Enter website">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click.prevent="onSubmit">
                        Submit
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <!-- </span> -->
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRadioGroup, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    required, name, email, phone, website,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import User from '../../../Helpers/User'

// import Roles from './roles.vue'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar, BFormRadioGroup,
        ValidationProvider, ValidationObserver, ToastificationContent, BFormCheckboxGroup
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            required,  name, email, phone, website,
            form: {
                name: '',
                email: '',
                phone: '',
                website: '',
            },
        }
    },



    methods: {
        checkPermission(permission) {

            return User.checkPermission(permission)
        },
        onReset(event) {
            event.preventDefault()
            this.clearForm()
        },
        clearForm() {
                this.form.name = '',
                this.form.email = '',
                this.form.phone = '',
                this.form.website = ''
        },

        onSubmit(event) {
            this.$refs.addUsersuppliers.validate().then(success => {
                if (success) {
                    try {
                        axios({
                            method: 'post',
                            url: process.env.VUE_APP_API + '/super-supplier',

                            data: {
                                'name': this.form.name,
                                'email': this.form.email,
                                'phone': this.form.phone,
                                'website': this.form.website,
                            }
                        })
                            .then(res => {
                                if (res.data.success) {
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: res.data.message,
                                                icon: 'AlertOctagonIcon',
                                                text: '',
                                                variant: 'success',
                                            },
                                        },
                                            {
                                                position: 'top-right'
                                            })
                                    this.$emit('refresh')
                                    this.closeSidebar()
                                }
                                else {
                                    this.$toast
                                        ({
                                            component: ToastificationContent,
                                            props: {
                                                title: 'Failed',
                                                icon: 'AlertOctagonIcon',
                                                text: res.data.success,
                                                variant: 'danger',
                                            },
                                        },
                                            {
                                                position: 'top-left'
                                            })
                                }
                            })
                    }
                    catch (error) {
                        console.log(error)
                    }
                }

            })
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-AddNewsupplier')
            this.clearForm()
        },
    },
};


</script>
